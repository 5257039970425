import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {box, confirm} from "./utils/MessageBox";
import './assets/css/reset.css';
import './assets/iconfont/iconfont.css';
import qs from 'qs';
import BigImg from './components/BigImg.vue'
const keyMap = new Map()
Vue.prototype.$keyMap = keyMap
Vue.prototype._box = box;
Vue.prototype._confirm = confirm;
// qs转化传输数据
Vue.prototype.$qs = qs
Vue.use(ElementUI);
Vue.use(BigImg);

// 开启之后，不会生产调试信息
Vue.config.productionTip = process.env.PRODUCTION_TIP


import * as commonUtils  from'./utils/common.js'
Vue.prototype.commonUtils = commonUtils;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
//设置路由导航守卫
router.beforeEach((to, from, next) => {
    // to要去的页面 from从哪里来 next放行
    console.log(to, from);
   localStorage.setItem('nowPage',to.path)
    var nowPage=localStorage.getItem('nowPage')
   if(from.name==null){
     router.push({path:nowPage})
   }
   next()
  })


